/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* transpose-table START */
table {
  white-space: nowrap;
}
/* single-row column design */
.transpose-table tr {
  display:  inline-block;
}
.transpose-table th,
.transpose-table td {
  display: block;
}

.cell-offset {
  width: 250px;
}
/* transpose-table END */

/* Hide the element on screen, but show it when printing */
.print-only {
  display: none; /* Hide by default */
}

@media screen {
  .print-only {
    display: none; /* Ensure it's hidden on screen */
  }
}

@media print {
  .print-only {
    display: block; /* Show only when printing */
  }
}
.cell-highlight {
  background-color:#d0d0d0;
}

.spinner-container {
  z-index: 100;
  position: fixed;
  top: 74px;
  right: 37px;
  display: 'flex';
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 15%);
  border-radius: 10%;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
