/* printPage.css */

/* Page setup */
@page {
  margin: 0.5cm;
  size: A4;
}

/* Screen-specific media queries */
@media only screen and (min-width: 1921px) {
  .h2ClassTop {
    font-size: 42px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1920px) {
  .h2ClassTop {
    font-size: 42px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1366px) {
  .h2ClassTop {
    font-size: 42px;
    font-weight: bold;
  }
}

/* Container positioning */
.container.px-4 {
  transform: translateY(-200px);
}

/* Responsive container adjustments */
@media (max-width: 1366px) {
  .container.px-4 {
    transform: translateY(-320px);
  }
}

@media (max-width: 768px) {
  .container.px-4 {
    transform: translateY(-150px);
  }
}

@media (max-width: 480px) {
  .container.px-4 {
    transform: translateY(-100px);
  }
}

/* Print-specific browser compatibility fixes */
@-moz-document url-prefix() {
  @media print {
    .watermark-print-wrapper {
      print-color-adjust: exact;
    }
  }
}

@media print and (-webkit-min-device-pixel-ratio:0) {
  .watermark-print-wrapper {
    -webkit-print-color-adjust: exact;
  }
}

/* Add these styles */
@media print {
  /* Enhanced table styles */
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0 !important;
  }

  tr {
    page-break-inside: avoid;
    margin: 0 !important;
    padding: 0 !important;
  }

  td, th {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    padding: 2px 3px !important;
    line-height: 1.2 !important;
    vertical-align: top;
  }

  /* Compact text styles */
  h1, h2, p, span {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.2 !important;
  }

  /* Remove extra spacing */
  .card-body {
    padding: 0.5rem !important;
  }

  .row {
    margin: 0 !important;
  }

  .d-flex {
    gap: 0.25rem !important;
  }

  /* Ensure consistent text sizing */
  .fs-6 {
    font-size: 0.9rem !important;
    line-height: 1.3 !important;
  }

  /* Adjust table header spacing */
  thead th {
    padding: 3px !important;
    line-height: 1.2 !important;
  }

  /* Handle nested tables */
  table table {
    margin: 0 !important;
  }
}